<template>
  <v-container>
    <v-row>
      <!-- 数据为空时显示提示 -->
      <template v-if="!isLoading && !articles.length">
        <v-col cols="12">
          <empty></empty>
        </v-col>
      </template>
      <!-- 数据不为空时显示卡片 -->
      <template v-else>
        <v-col v-for="article in articles"
          :key="article.id"
          xl="3"
          lg="4"
          md="6"
          cols="12"
          class="pa-2"
        >
          <article-card
            :article="article"
          ></article-card>
        </v-col>
      </template>
    </v-row>
    <v-row>
      <v-col>
        <load-more
          loadingTips="更多文章正在加载中~"
          endTips="没有更多文章了~"
          color="#536dfe"
          :threshold="1.0"
          :loading="isLoading"
          :end="isEnd"
          @refresh="loadMoreArticles"
        ></load-more>
      </v-col>
    </v-row>
    <comment-board :type="1"></comment-board>
  </v-container>
</template>

<script>
import ArticleCard from 'components/ArticleCard.vue'
import CommentBoard from '@/components/CommentBoard.vue'
import LoadMore from '@/components/app/LoadMore.vue'
import { getArticleList } from '@/apis/article'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    ArticleCard,
    CommentBoard,
    LoadMore
  },
  data () {
    return {
      pageIndex: 0,
      isEnd: false,
      isEmpty: false,
      isLoading: false,
      articles: []
    }
  },
  computed: {
    ...mapState('app', ['editTimeCollection']),
    // 每行的卡片数
    countPerRow () {
      const breakpoint = this.$vuetify.breakpoint
      if (breakpoint.xl) {
        return 4
      } else if (breakpoint.lg) {
        return 3
      } else if (breakpoint.md) {
        return 2
      } else {
        return 1
      }
    },
    // 顶栏高度
    appbarHeight () {
      return this.$vuetify.application.top
    },
    // 内容高度
    contentHeight () {
      return window.innerHeight - this.appbarHeight
    },
    // 一屏的卡片行数
    rows () {
      // TODO: 目前是取定值，无法应对高度变更的情况
      const CARD_HEIGHT = 406
      // 多加载一行
      return Math.floor(this.contentHeight / CARD_HEIGHT + 1)
    },
    // 分页时每页的卡片数
    pageSize () {
      return this.rows * this.countPerRow
    }
  },
  methods: {
    ...mapMutations('app', ['updateArticleEditTime']),
    // 如果是**新文章**，更新文章编辑时间到 vuex
    updateCache (list) {
      list.forEach(item => {
        const hasCache = this.editTimeCollection[item.id]
        if (!hasCache) {
          this.updateArticleEditTime({
            id: item.id,
            time: item.updateTime
          })
        }
      })
    },
    loadMoreArticles (reload = false) {
      if (this.isEnd) {
        return
      }
      if (reload) {
        this.pageIndex = 1
      } else {
        this.pageIndex++
      }
      this.isLoading = true
      getArticleList({
        orderDirection: 'desc',
        tags: '77134',
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }).then(({ data: list }) => {
        this.isEnd = (list || []).length < this.pageSize
        this.updateCache(list)
        this.articles = this.articles.concat(list)
      }).finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
